<template>
  <section>
    <DashboardTemplate :sections="filteredSections" :grid-rows="gridRows()" :grid-area-component="gridComponent()">
      <template #navbarItems>
        <div class="dashboard-navbar__route">
          {{ $t("dashboard.title") }}
        </div>
      </template>
    </DashboardTemplate>
    <v-dialog
      v-model="isShowDigitalProfile"
      content-class="feedback-dialog"
      transition="dialog-bottom-transition"
      max-width="700"
      persistent
    >
      <CampusDetail in-dashboard />
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardTemplate from '@/components/dashboard/Template.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';

export default {
  name: 'DashboardComponent',
  components: {
    DashboardTemplate, CampusDetail,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.panel')}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('dashboard.meta-content'),
        },
        { name: 'robots', content: 'noindex, nofollow' },
      ],
    };
  },
  data() {
    return {
      loaded: false,
      role: 'parent',
      searchInput: '',
      gridAreaRowsLG: 4,
      gridAreaRowsHM: 6,
      gridAreaComponentLG: '"profile-edit profile-edit dge-lg-applications dge-lg-applications school-list school-list" "tools tools dge-lg-applications dge-lg-applications school-list school-list" "white-label white-label . . . ." "white-label white-label . . . ."',
      gridAreaComponentHM: '"digital-profile-edit digital-profile-edit image-gallery image-gallery tools-headmaster tools-headmaster" "digital-profile-edit digital-profile-edit image-gallery image-gallery tools-headmaster tools-headmaster" "digital-profile digital-profile tools-stats tools-stats analytics analytics" "digital-profile digital-profile tools-stats tools-stats analytics analytics" "d-f-m d-f-m . . . ." "d-f-m d-f-m . . . ."',
    };
  },
  computed: {
    ...mapGetters({
      headMasterSchools: 'roles/currentHeadMasterSchools',
      userUsername: 'authentication/userUsername',
      isGuest: 'authentication/isGuest',
      isHeadMaster: 'roles/isHeadMaster',
      isAmbassador: 'roles/isAmbassador',
      isLegalGuardian: 'roles/isLegalGuardian',
      userFirstLogin: 'authentication/userFirstLogin',
      currentStudent: 'authentication/currentStudent',
      students: 'authentication/students',
      preCheckStatus: 'authentication/preCheckStatus',
      headMasterPermissions: 'roles/headMasterPermissions',
      legalGuardianPermissions: 'roles/legalGuardianPermissions',
      ambassadorPermissions: 'roles/ambassadorPermissions',
      permissions: 'roles/permissions',
      isShowDigitalProfile: 'feedback/isShowDigitalProfile',
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    roleSections() {
      if (this.isLegalGuardian || this.isHeadMaster || this.isAmbassador) {
        return this.permissions.map((s) => s.module_name);
      }
      return [{ name: 'skeleton' }, { name: 'skeleton' }, { name: 'skeleton' }];
    },
    filteredSections() {
      return this.sections.filter(
        (section) => this.roleSections.includes(section.name)
        && section.title.normalize('NFD').toLowerCase().includes(this.searchInput.toLowerCase()),
      );
    },
    hasTetherPack() {
      return this.campusDetail?.config_campus[0]?.campus_tether_pack ?? false;
    },
    sections() {
      const sectionInfo = [
        {
          name: 'profile-edit',
          background: 'transparent',
          title: this.$t('dashboard.widget-title.profile'),
          showTitle: false,
          fullSpace: true,
          gridArea: 'profile-edit',
          mobileHeight: '150px',
        },
        {
          name: 'digital-profile-edit',
          showTitle: false,
          title: this.$t('dashboard.widget-title.digital-profile'),
          paddingSection: '0px',
          fullSpace: true,
          background: 'transparent',
          gridArea: 'digital-profile-edit',
        },
        {
          name: 'profile',
          title: this.$t('dashboard.widget-title.profile'),
          permissions: ['student'],
          background: 'white',
          gridArea: 'profile',
        },
        {
          name: 'image-gallery',
          title: this.$t('dashboard.widget-title.image-gallery'),
          paddingSection: '20px',
          gridArea: 'image-gallery',
        },
        {
          name: 'tools-headmaster',
          title: '',
          showTitle: false,
          permissions: ['headmaster'],
          background: 'white',
          fullSpace: true,
          gridArea: 'tools-headmaster',
        },
        {
          name: 'digital-profile',
          title: this.$t('dashboard.widget-title.digital-profile'),
          showTitle: false,
          permissions: ['headmaster'],
          paddingSection: '20px',
          gridArea: 'digital-profile',
        },
        {
          name: 'tools-stats',
          showTitle: false,
          title: this.$t('dashboard.widget-title.tools'),
          permissions: ['headmaster'],
          background: 'transparent',
          fullSpace: true,
          gridArea: 'tools-stats',
        },
        {
          name: 'analytics',
          title: 'Análisis y data',
          permissions: ['headmaster'],
          fullSpace: true,
          gridArea: 'analytics',
        },
        {
          name: 'd-f-m',
          title: '',
          fullSpace: true,
          permissions: ['headmaster'],
          gridArea: 'd-f-m',
        },
        // {
        //   name: 'messages-headmaster',
        //   title: 'Mensajería',
        //   permissions: ['headmaster'],
        //   paddingSection: '20px',
        //   gridArea: 'messages-headmaster',
        // },
        {
          name: 'admissions',
          title: this.$t('dashboard.widget-title.addmission'),
          paddingSection: '20px',
          gridArea: 'admissions',
        },
        {
          name: 'school-list',
          title: this.$t('dashboard.widget-title.school-list'),
          showTitle: false,
          paddingSection: '15px',
          gridArea: 'school-list',
        },
        {
          name: 'tools',
          title: this.$t('dashboard.widget-title.tools'),
          showTitle: false,
          permissions: ['parent'],
          background: 'white',
          fullSpace: true,
          isTool: true,
          gridArea: 'tools',
          mobileHeight: '150px',
        },
        {
          name: 'white-label',
          title: '',
          showTitle: false,
          paddingSection: '20px',
          permissions: ['parent'],
          background: '#F5F5F5',
          gridArea: 'white-label',
          bodyHeight: 'auto',
        },
        {
          name: 'upcoming-events',
          title: '',
          showTitle: false,
          paddingSection: '0px',
          permissions: ['parent'],
          background: '#F5F5F5',
          gridArea: 'upcoming-events',
          bodyHeight: 'auto',
        },
        {
          name: 'messages',
          title: this.$t('dashboard.widget-title.notifications'),
          paddingSection: '20px',
          gridArea: 'messages',
        },
        {
          name: 'dashboard-map',
          title: this.$t('dashboard.widget-title.dashboard-map'),
          background: 'white',
          gridArea: 'dashboard-map',
        },
        {
          name: 'dge-headmaster-info',
          title: 'Postulantes & Interesados',
          background: '#F5F5F5',
          gridArea: 'dge-headmaster-info',
          show: this.hasTetherPack,
        },
        {
          name: 'feedback',
          title: '',
          showTitle: false,
          permissions: ['parent'],
          background: '#F5F5F5',
          gridArea: 'feedback',
        },
        // {
        //   name: 'ensena-cl',
        //   title: 'Enseña Chile',
        //   showTitle: false,
        //   gridArea: 'ensena-cl',
        // },
        {
          name: 'dge-lg-applications',
          title: 'Tus Postulaciones',
          showTitle: false,
          gridArea: 'dge-lg-applications',
        },
        {
          name: 'simulation',
          title: this.$t('dashboard.widget-title.simulation'),
          permissions: ['parent'],
          showTitle: false,
          background: '#F5F5F5',
          gridArea: 'simulation',
        },
        {
          name: 'location',
          title: '',
          permissions: ['parent'],
          showTitle: false,
          background: '#F5F5F5',
          gridArea: 'location',
          paddingSection: '0px',
        },
        {
          name: 'public-register',
          title: '',
          permissions: ['parent'],
          showTitle: false,
          background: '#F5F5F5',
          gridArea: 'public-register',
          paddingSection: '0px',
        },
        {
          name: 'public-register-admin',
          title: '',
          showTitle: false,
          background: '#F5F5F5',
          gridArea: 'public-register-admin',
          paddingSection: '0px',
        },
      ];
      return sectionInfo;
    },
  },
  mounted() {
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'dashboard.breadcrumbs.panel',
          route: this.$route.path,
        },
      ],
    });
    this.retrieveRole();
    this.setIsShowDigitalProfile(false);
  },
  methods: {
    ...mapActions({
      setIsShowDigitalProfile: 'feedback/setIsShowDigitalProfile',
      setBreadcrumbs: 'utils/setBreadcrumbs',
    }),
    retrieveRole() {
      if (this.isHeadMaster) {
        this.role = 'headmaster';
        this.loaded = true;
      } else if (this.isLegalGuardian) {
        this.role = 'parent';
        this.loaded = true;
      } else if (this.isAmbassador) {
        this.role = 'ambassador';
        this.loaded = true;
      }
    },
    gridComponent() {
      return this.isLegalGuardian ? this.gridAreaComponentLG : this.gridAreaComponentHM;
    },
    gridRows() {
      return this.isLegalGuardian ? this.gridAreaRowsLG : this.gridAreaRowsHM;
    },
  },
};
</script>
